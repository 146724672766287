import React, { Component, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";

import ScrollAnimation from "react-animate-on-scroll";
// import { img } from 'gatsby-plugin-image';

import "../../styles/clientTestimonials.scss";
import "../../styles/resources.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img1 from "../../images/resources/r1.png";
import Img2 from "../../images/resources/r2.png";
import Img3 from "../../images/resources/r3.png";
import Img4 from "../../images/resources/r4.png";
// import { img } from 'gatsby-plugin-image';

const HelpfulResources = ({ data }: { data: any }) => {
  const posts = [
    {
      img: Img1,
      desc: "STRATEGIX GROUP COMPANY PROFILE",
      url: "media/Strategix_Company_Profile.pdf",
    },
    {
      img: Img2,
      desc: "STRATEGIX MANAGED SERVICES OFFERING",
      url: "media/Managed_Services_Offerings.pdf",
    },
    {
      img: Img3,
      desc: "STRATEGIX PROFESSIONAL SERVICES ENGAGEMENT METHODOLOGY",
      url: "/professional-services",
    },
    {
      img: Img4,
      desc: "STRATEGIX TECHNOLOGY SOLUTIONS VEEAM O365 BACKUP & RESTORE IN AZURE",
      url: "media/Strategix-Tech-Solutions-MS-Veeam-O365.pdf",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: true,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="carousel-wrapper"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="w-max-nf">
        <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
          <h1 style={{ marginTop: "0" }}>Helpful Resources</h1>
          <p>The latest from Strategix Technology Solutions.</p>
        </ScrollAnimation>
        <br />
        <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
          <Slider {...settings}>
            {posts.map((post: any) => (
              <div className="resource-carousel">
                <div
                  className="resource-slide"
                  style={{
                    minHeight: "25vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href={post.url} target="_blank">
                    <img
                      alt=""
                      src={post.img}
                      className="resources-carousel-logo"
                      placeholder="blurred"
                      style={{
                        borderRadius: "50%",
                        transform: "scale(1)",
                      }}
                    />
                    <p>{post.desc}</p>
                  </a>
                </div>
                <br />
              </div>
            ))}
          </Slider>
        </ScrollAnimation>
        <br />
        <br />
      </div>
    </div>
  );
};

export default HelpfulResources;
