import * as React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";

import BlogCarousel from "../components/resources/blogCarousel";
import HelpfulResources from "../components/resources/helpfulResources";
import ScrollAnimation from "react-animate-on-scroll";

//Images

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import ContactForm from "../components/ContactForm";
import SEO from "../components/seo";

// markup
const ResourcesPage = () => {
  const props = {
    seoTitle: "Resource Centre",
    seoDescription:
      "Stay up to date with the latest from Strategix Technology Solutions and the information technology industry. Helpful resources, blogs and expert insight.",
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="resources">
            <div className="flex-col-c">
              <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                <h1>Resource Centre</h1>
                <p>
                  Helpful resources, industry news, partner offerings and expert
                  insight.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        {/* Blog */}
        <div className="blog-carousel-wrapper">
          <HelpfulResources />
          <BlogCarousel />
        </div>

        {/* Contact */}
        <section className="half">
          <ContactForm />
        </section>
      </main>
    </Layout>
  );
};

export default ResourcesPage;

const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
          slug
        }
        excerpt
        id
      }
    }
  }
`;
