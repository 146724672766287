import React, { Component, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";

import ScrollAnimation from "react-animate-on-scroll";
// import { img } from 'gatsby-plugin-image';

import "../../styles/clientTestimonials.scss";
import "../../styles/resources.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img1 from "../../markdown-pages/cloudy.png";
import Img2 from "../../markdown-pages/ict.png";
import Img3 from "../../markdown-pages/digital-transformation.png";
import Img4 from "../../markdown-pages/remote-working.png";
import Img5 from "../../markdown-pages/sts-logo.png";
import Img6 from "../../markdown-pages/strategix-technology-solutions-adp-rubrik.png";
import Img7 from "../../markdown-pages/AVS.webp";
import Img8 from "../../markdown-pages/ransomware.webp";
import Img9 from "../../markdown-pages/ransomware.png";
import Img10 from "../../markdown-pages/data-backup.png";
import Img11 from "../../markdown-pages/cloud-native.png";
import Img12 from "../../markdown-pages/held-ransom.png";
import Img13 from "../../markdown-pages/protection.webp";
import Img14 from "../../markdown-pages/ransomware2.webp";
import Img15 from "../../markdown-pages/anatomy.png";
import Img16 from "../../markdown-pages/top5.png";
import Img17 from "../../markdown-pages/ecosystems.png";
import Img18 from "../../markdown-pages/image1.png";
import Img19 from "../../markdown-pages/image2.png";

import { graphql, Link } from "gatsby";

export default function BlogCarousel({ data }: { data: any }) {
  const blogs = [
    {
      img: Img1,
      title: "Cloudy, with a chance of innovation.",
      date: "2021-07-22",
      author: "Steve Dalgarno",
      desc: "",
      url: "/blog/cloudy-innovation",
    },
    {
      img: Img2,
      title:
        "We’re the best-kept secret in SA’s ICT industry. But not for long.",
      date: "2021-06-21",
      author: "Steve Dalgarno",
      desc: "",
      url: "/blog/best-kept-secret-ict",
    },
    {
      img: Img3,
      title: "Embracing digital transformation as platform for growth",
      date: "2021-05-25",
      author: "Steve Dalgarno",
      desc: "",
      url: "/blog/embracing-digital-transformation",
    },
    {
      img: Img4,
      title: "Remote working is here to stay – but is your company protected?",
      date: "2021-06-18",
      author: "Jonathan Goldberg",
      desc: "",
      url: "/blog/remote-working",
    },
    {
      img: Img5,
      title: "Strategix Technology Solutions Launches New Website.",
      date: "2022-06-17",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/strategix-technology-solutions-launches-new-website",
    },
    {
      img: Img6,
      title:
        "Strategix partners with ADP to bring Rubrik Data Protection to Africa.",
      date: "2022-07-18",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/strategix-adp-bring-rubrik-data-protection-to-africa",
    },
    {
      img: Img7,
      title: "Transition to cloud seamlessly with Azure VMware Solution.",
      date: "2022-09-16",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/transition-to-cloud-with-azure-vmware-solution",
    },
    {
      img: Img8,
      title: "Ransomware: the digital smoking gun.",
      date: "2022-09-23",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/ransomware-protection",
    },
    {
      img: Img9,
      title:
        "The high cost of ransomware: what a breach really costs your company.",
      date: "2023-03-10",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/high-cost-ransomware-breach-really-costs-company",
    },
    {
      img: Img10,
      title: "Data resilience: The value of data backup and recovery.",
      date: "2023-03-10",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/data-resilience-value-data-backup-recovery",
    },
    {
      img: Img11,
      title: "Is Your Business Ready For The Cloud Native Era?",
      date: "2023-05-04",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/is-your-business-ready-for-the-cloud-native-era",
    },
    {
      img: Img13,
      title:
        "Data protection as a service: The antidote to sophisticated cyber threats?",
      date: "2023-04-20",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/data-protection-as-a-service",
    },
    {
      img: Img14,
      title: "Held to ransom: the rising threat of ransomware (Part 01)",
      date: "2023-04-20",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/the-rising-threat-of-ransomware",
    },
    {
      img: Img12,
      title: "Held to ransom: the rising ransomware threat (Part 02).",
      date: "2023-05-04",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/held-to-ransom-the-rising-ransomware-threat-part2",
    },
    {
      img: Img15,
      title: "The anatomy of a South African ransomware attack",
      date: "2023-06-02",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/the-anatomy-of-a-south-african-ransomware-attack",
    },
    {
      img: Img16,
      title: "The Top 5 Cloud Trends of 2023 (so far)",
      date: "2023-07-04",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/the-top-5-cloud-trends-of-2023",
    },
    {
      img: Img17,
      title:
        "Creating sustainable digital ecosystems with ethical partnerships",
      date: "2023-07-04",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/creating-sustainable-digital-ecosystems",
    },
    {
      img: Img18,
      title: "Six strategies to mitigate VMware vulnerabilities",
      date: "2023-11-29",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/six-strategies-to-mitigate-vmware-vulnerabilities",
    },
    {
      img: Img19,
      title: "The critical considerations (and solutions) for data sovereignty",
      date: "2023-11-29",
      author: "Strategix Technology Solutions",
      desc: "",
      url: "/blog/the-critical-considerations-and-solutions-for-data-sovereignty",
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: true,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="carousel-wrapper"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="w-max-nf">
        <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
          <h1 style={{ marginTop: "0" }}>News, Blogs and Industry Insights</h1>
          <p>Stay up to date with the latest in information technology.</p>
        </ScrollAnimation>
        <br />
        <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
          <Slider {...settings}>
            {blogs.map((blog: any) => (
              <div className="blog-carousel">
                <div
                  className="blog-slide"
                  style={{
                    minHeight: "25vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link to={blog.url}>
                    <img
                      src={blog.img}
                      className="blog-carousel-logo"
                      placeholder="blurred"
                      style={{
                        borderRadius: "50%",
                        transform: "scale(1)",
                      }}
                    />

                    <p
                      style={{
                        fontSize: "small",
                        paddingTop: "20px",
                        paddingBottom: "0",
                        minHeight: "0",
                      }}
                    >
                      {blog.date} &nbsp;|&nbsp; {blog.author}
                    </p>
                    <h3>{blog.title}</h3>
                    {/* <p>{blog.desc}</p> */}
                  </Link>
                  <br />
                  <br />
                </div>
              </div>
            ))}
          </Slider>
        </ScrollAnimation>
        <br />
        <br />
      </div>
    </div>
  );
}
